import React from 'react';
import { Link, graphql } from 'gatsby';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import ReactPlayer from 'react-player';

import { Layout } from '@/layout/Layout';
import Seo from '../components/common/Seo';

import VIDAA from '@/assets/images/VIDAA.svg';
import VIDAA_LOGO from '@/assets/images/vidaa/VIDAA.png';
import group_icon from '@/assets/images/vidaa/group_icon.png';
import VIDAA_BLACK from '@/assets/images/vidaa/VIDAA_BLACK.svg';
import VidaaCentral from '@/assets/images/vidaa/logo-central-VIDAA.png';

const Vidaa = ({ data }) => {
  const {
    cards_section_text,
    cards,
    youtube_video,
    privacy_note,
    apps_section_text,
    app_images,
    voice_control_text,
    voice_control_image,
    voice_control_mobile_image,
    remote_section_text,
    remote_image,
    remote_mobile_image,
    vidaa_section_text,
    vidaa_image,
    vidaa_mobile_image,
  } = data.directus.vidaa;
  return (
    <Layout pageId="vidaa" isHeaderTransparent={true} headerWhiteText={true}>
      <section
        className="h-[300px] lg:h-[400px]"
        style={{
          background: 'linear-gradient(270deg, rgba(0, 105, 255, 1) 0%, rgba(254, 65, 157, 1) 100%)',
          clipPath: 'polygon(0% 0%, 100% 0%, 100% 370px, 0% 100%)',
        }}
      >
        <div className="container flex h-[300px] w-full items-center justify-center lg:h-[400px]">
          <img src={VIDAA_LOGO} alt="Vidaa" className="mr-4 h-[45px] lg:h-[94px]" />
          <img src={VIDAA} alt="" className="mr-4 h-[45px] lg:h-[94px]" />
        </div>
      </section>

      <section
        className="-mt-16 bg-[#303030] pb-12 lg:pb-96"
        style={{ clipPath: 'polygon(0% 20px, 100% 0%, 100% 100%, 0% 100%)' }}
      >
        <div className="container">
          <div className="row">
            <div
              className="mx-auto mb-9 mt-16 lg:col-8 lg:mb-24 lg:mt-28 [&>p]:text-center [&>p]:text-2xl [&>p]:text-white [&>p]:lg:text-3xl"
              dangerouslySetInnerHTML={{ __html: cards_section_text }}
            />

            <div className="mx-auto flex w-full flex-col items-start gap-y-12 lg:col-11 lg:flex-row">
              {cards.map((card) => {
                return (
                  <div
                    key={card.id}
                    className="mt-[20p] flex flex-1 flex-row justify-center gap-4 text-white lg:flex-col lg:items-center lg:gap-9"
                  >
                    <div className="shrink-0">
                      <img src={card.image.imageFile.publicURL} alt="" className="h-[72px] w-[72px]" />
                    </div>
                    <div className="flex flex-col text-white lg:mx-auto lg:max-w-[248px]">
                      <span className="mb-2 text-xl font-bold lg:mb-3 lg:text-center lg:text-2xl">{card.title}</span>
                      <span className="text-sm font-light lg:text-center">{card.body}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      <div className="container z-10 px-0 lg:-mt-80 lg:px-4">
        <div className="row">
          <div className="relative mx-auto pt-[56.25%] lg:col-10">
            <ReactPlayer className="absolute left-0 top-0" url={youtube_video} width="100%" height="100%" />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="mt-14 flex w-full justify-center lg:mt-16">
            <img src={group_icon} alt="" />
          </div>

          <div className="mx-auto mb-12 mt-7 text-center lg:col-8 lg:mb-16 lg:mt-9">
            <span className="text-sm font-light text-[#697276]" dangerouslySetInnerHTML={{ __html: privacy_note }} />
          </div>
        </div>
      </div>

      <section className="bg-[#F4F4F4] py-12 lg:py-14">
        <div className="container flex flex-col lg:flex-row">
          <div className="row justify-between">
            <div className="lg:col-4">
              <div
                className="text-center text-2xl font-light lg:text-left [&>p]:text-[#303030]"
                dangerouslySetInnerHTML={{ __html: apps_section_text }}
              />
              <div className="mt-7 flex justify-center lg:mt-3 lg:justify-start">
                <img src={VIDAA_BLACK} alt="" style={{ height: '43px', marginRight: '20px' }} />
                <img src={VIDAA} alt="" style={{ height: '53px' }} />
              </div>
            </div>

            <div className="mt-14 flex flex-wrap justify-center gap-x-2 gap-y-4 lg:col-8 lg:mt-0 lg:gap-6">
              {app_images.map((app) => (
                <div key={app.file_id.id}>
                  <img src={app.file_id.imageFile.publicURL} alt={app.file_id.title} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="relative w-full" id="alexa-section">
        <GatsbyImage
          image={getImage(voice_control_mobile_image.imageFile)}
          alt=""
          className="h-auto w-full md:!hidden"
        />
        <GatsbyImage
          image={getImage(voice_control_image.imageFile)}
          alt=""
          className="!hidden h-auto w-full object-cover md:!block"
        />

        <div className="container absolute left-0 right-0 top-14 z-10">
          <div className="row">
            <div className="mx-auto text-center lg:col-8" dangerouslySetInnerHTML={{ __html: voice_control_text }} />
          </div>
        </div>
      </section>

      <section className="relative w-full" id="remote-section">
        <GatsbyImage image={getImage(remote_mobile_image.imageFile)} alt="" className="h-auto w-full md:!hidden" />
        <GatsbyImage
          image={getImage(remote_image.imageFile)}
          alt=""
          className="!hidden h-auto w-full object-cover md:!block"
        />

        <div className="container absolute left-0 right-0 top-[10%] z-10 md:top-1/2 md:-translate-y-1/2">
          <div className="md:w-[40%]">
            <div dangerouslySetInnerHTML={{ __html: remote_section_text }} />
            <div className="hidden gap-4 md:flex">
              <StaticImage src="../assets/icons/app-store.png" alt="" height={44} />
              <StaticImage src="../assets/icons/google-play.png" alt="" height={44} />
            </div>
          </div>
        </div>
      </section>

      <section className="relative w-full pb-10" id="vidaa-section">
        <GatsbyImage image={getImage(vidaa_mobile_image.imageFile)} alt="" className="h-auto w-full md:!hidden" />
        <GatsbyImage
          image={getImage(vidaa_image.imageFile)}
          alt=""
          className="!hidden h-auto w-full object-cover md:!block"
        />

        <div className="container absolute bottom-0 left-0 right-0 z-10">
          <div className="row">
            <div className="mx-auto text-center lg:col-8">
              <div className="flex justify-center">
                <img src={VidaaCentral} alt="" style={{ height: '50px', width: '200px', margin: '20px 0' }} />
              </div>
              <div className="text-content" dangerouslySetInnerHTML={{ __html: vidaa_section_text }} />
            </div>
          </div>
        </div>
      </section>

      <section className="py-20">
        <div className="container">
          <div className="row">
            <div className="mx-auto text-center lg:col-8">
              <div className="flex flex-col items-center justify-center gap-4 rounded-2xl bg-[#F4F4F4] px-8 py-6 lg:flex-row lg:px-10 lg:py-7">
                <p className="text-center text-2xl font-bold text-[#303030] lg:text-left">
                  Conoce todos los televisores con tecnología VIDAA de Hisense Perú
                </p>
                <Link to="/tv" className="btn shrink-0">
                  Ver productos
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const Head = () => <Seo title="Vidaa | Hisense" />;

export const query = graphql`
  query VidaaPageQuery {
    directus {
      vidaa {
        cards_section_text
        cards {
          id
          title
          body
          image {
            id
            imageFile {
              publicURL
            }
          }
        }
        youtube_video
        privacy_note
        apps_section_text
        app_images {
          file_id {
            id
            imageFile {
              publicURL
            }
          }
        }
        voice_control_text
        voice_control_image {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        voice_control_mobile_image {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        remote_section_text
        remote_image {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        remote_mobile_image {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        vidaa_section_text
        vidaa_image {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        vidaa_mobile_image {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;

export default Vidaa;
